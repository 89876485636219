<template>
  <section>
    <b-table
      :data="isEmpty ? [] : listRequests"
      ref="tableR"
      :paginated="totalRequests > 10 ? true : false"
      per-page="10"
      :loading="isLoading"
    >
     
        <b-table-column field="id" label="ID" width="40" numeric sortable v-slot="props">
          {{ props.row.id }}
        </b-table-column>
        <b-table-column field="title" label="Title" v-slot="props">
          <a @click="showDetails(props.row)">
            {{ props.row.title }}
          </a>
        </b-table-column>
        <b-table-column field="category" label="Category" v-slot="props">
          {{ getCategory(props.row.category) }}
        </b-table-column>
        <b-table-column field="location" label="Location" v-slot="props">
          <ItemLocation :islocation="props.row.location.toString()" />
        </b-table-column>

        <b-table-column custom-key="actions" label="Unrequest" v-slot="props">
          <button
            class="button is-small is-danger"
            @click="removeR(props.row.id)"
          >
            <b-icon class="iconmargin" icon="times" size="is-small"></b-icon>
            <span>Remove</span>
          </button>
        </b-table-column>
     

      <template slot="empty">
        <section class="section">
          <div class="content has-text-grey has-text-centered">
            <p>
              <b-icon :icon="icon" size="is-large"> </b-icon>
            </p>
            <p>No Requests.</p>
          </div>
        </section>
      </template>
    </b-table>
  </section>
</template>

<script>

import { mapState } from "vuex";
import ItemLocation from "@/components/ItemLocation.vue";

export default {
  name: "RequestList",
  components: {
    ItemLocation
  },
  props: {
    icon: String
  },
  data() {
    return {
      published: true,
      paginated: false,
      isEmpty: false,
      isLoading: true,
      listRequests: [],
      totalRequests: null,
      listIDS: ""
    };
  },
  methods: {
    loadData() {
      this.listIDS = this.requests.join(",");
      //console.log(this.requests.join(","));
      this.axios
        .post("data/get_data.php", {
          token: this.$store.getters.IS_JWT,
          action: "listItemsIn",
          listIDS: this.listIDS
        })
        .then(response => {
          //console.log(response.data);
          this.isLoading = false;
          if (response.data.success) {
            this.listRequests = response.data.items;

            this.totalRequests = this.listRequests.length;
            console.log("Total Result:" + this.listRequests.length);
            // this.totalItems paginated
          } else {
            this.hasItems = false;
            this.isEmpty = true;
            console.log("Error getting data");
          }
        })
        .catch(error => {
          this.hasItems = false;
          this.isEmpty = true;
          console.log(error);
          //this.errored = true;
        });
      //.finally(() => (this.loading = false));
    },
    removeR(id) {
      this.$store.commit("REM_NUM", id);
    },
    toggle(row) {
      this.$refs.table.toggleDetails(row);
    },
    unrequest(id) {
      this.$buefy.toast.open("Unrequesting" + id);
    },
    tags(intags) {
      return intags.split(",");
    },
    showDetails(item) {
      this.$store.commit("SET_ITEMDATA", item);
      this.$store.commit("SHOW_MODAL", { modal: "itemShow", state: true });
    },
    getCategory(catID){
      //catID
      if(!this.categories){return "-";}
      let cat = this.categories.find(item => item.id === parseInt(catID));
        
      if(cat){
          return cat.name;
      }else{
          return "-";
      }     
    },
  },
  computed: {
    ...mapState(["rNum", "requests", "categories"])
  },
  watch: {
    rNum(newValue, oldValue) {
      //console.log(`Updating from ${oldValue} to ${newValue}`);
      this.loadData();
    }
  },
  mounted() {
    this.loadData();
    //let ids = this.$store.getters.REQUESTS;
  }
};
</script>
<style scoped>
.iconmargin {
  margin-right: 5px;
}
</style>
