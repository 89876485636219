<template>
  <div>
    <b-menu>
      <b-menu-list label="My Account">
        <b-menu-item
          tag="router-link"
          :to="{ path: '/my-account' }"
          icon-pack="fas"
          icon="user-edit"
          label="Contact Info"
          :active="isActive.settings"
        ></b-menu-item>
        <b-menu-item
          tag="router-link"
          :to="{ path: '/requests' }"
          icon-pack="fas"
          icon="clipboard-check"
          label="Requests"
          :active="isActive.order"
        ></b-menu-item>
        <b-menu-item
          tag="router-link"
          :to="{ path: '/order-history' }"
          icon-pack="fas"
          icon="history"
          label="Order History"
          :active="isActive.history"
        ></b-menu-item>
      </b-menu-list>
    </b-menu>
  </div>
</template>

<script>
export default {
  name: "UserMenu",
  props: {
    activeElement: String
  },

  data() {
    return {
      isActive: {
        settings: false,
        history: false,
        order: false
      }
    };
  },
  methods: {},
  mounted() {
    this.isActive[this.activeElement] = true;
    console.log(this.isActive[this.activeElement]);
    console.log(this.activeElement);
  }
};
</script>
<style scoped></style>
