<template>
  <div>
    <section class="hero is-light header">
      <b-loading
        :is-full-page="true"
        :active.sync="isLoading"
        :can-cancel="false"
      ></b-loading>
      <div class="hero-body">
        <div class="container">
          <div class="header-title">Requests</div>
          <div class="header-subtitle">Requested items from storage</div>
        </div>
      </div>
    </section>

    <section class="section mainsection">
      <div class="container">
        <div class="columns">
          <div class="column is-3">
            <UserMenu activeElement="order" />
          </div>
          <div class="column">
            <div class="card">
              <header class="card-header">
                <p class="card-header-title">
                  Requests
                </p>
              </header>
              <div class="card-content">
                <RequestList :key="RequestListKey" :icon="RequestListIcon" />
                <div v-if="this.rNum > 0">
                
                <div class="is-divider" data-content="Project & Delivery"></div>

                      <b-field
                        label="Project"
                        :type="form.project.status"
                        :message="form.project.message"
                        expanded
                        class="mb-5"
                      >
                        <b-input
                          v-model="form.project.data"
                          placeholder="Project Name"
                        ></b-input>
                      </b-field>

                  <b-field label="Deliver To:">
                    <b-switch v-model="delivery" :rounded="false"
                      >Default Address</b-switch
                    >
                  </b-field>
                  <div v-if="!delivery">
                    <b-field grouped>
                      <b-field
                        label="Name"
                        :type="form.name.status"
                        :message="form.name.message"
                        expanded
                      >
                        <b-input
                          v-model="form.name.data"
                          placeholder="Your Full Name"
                        ></b-input>
                      </b-field>
                    </b-field>

                    <b-field grouped>
                      <!-- <b-field
                        label="Email"
                        :type="form.email.status"
                        :message="form.email.message"
                        expanded
                      >
                        <b-input
                          v-model="form.email.data"
                          placeholder="Your Email Address"
                        ></b-input>
                      </b-field> -->
                      <b-field
                        label="Company"
                        :type="form.company.status"
                        :message="form.company.message"
                        expanded
                      >
                        <b-input
                          v-model="form.company.data"
                          placeholder="Company Name"
                        ></b-input>
                      </b-field>
                      <b-field
                        label="Phone"
                        :type="form.phone.status"
                        :message="form.phone.message"
                        expanded
                      >
                        <b-input
                          v-model="form.phone.data"
                          placeholder="Your Phone Number"
                        ></b-input>
                      </b-field>
                    </b-field>

                    <b-field grouped>
                      <b-field
                        label="Street Address"
                        :type="form.address.status"
                        :message="form.address.message"
                        expanded
                      >
                        <b-input
                          v-model="form.address.data"
                          placeholder="Your Street Address"
                        ></b-input>
                      </b-field>
                      <b-field
                        label="Optional"
                        :type="form.optional.status"
                        :message="form.optional.message"
                        expanded
                      >
                        <b-input
                          v-model="form.optional.data"
                          placeholder="Sute, Unit, Building, Floor, etc."
                        ></b-input>
                      </b-field>
                    </b-field>

                    <b-field grouped>
                      <b-field
                        label="City"
                        :type="form.city.status"
                        :message="form.city.message"
                        expanded
                      >
                        <b-input v-model="form.city.data"></b-input>
                      </b-field>
                      <b-field
                        label="Postal / Zip Code"
                        :type="form.postal.status"
                        :message="form.postal.message"
                        expanded
                      >
                        <b-input v-model="form.postal.data"></b-input>
                      </b-field>
                    </b-field>

                    <b-field grouped>
                      <b-field
                        label="Province / State"
                        :type="form.state.status"
                        :message="form.state.message"
                        expanded
                      >
                        <b-input
                          v-model="form.state.data"
                          placeholder="Province, State, Region"
                        ></b-input>
                      </b-field>
                      <b-field
                        label="Country"
                        :type="form.country.status"
                        :message="form.country.message"
                        expanded
                      >
                        <b-input v-model="form.country.data"></b-input>
                      </b-field>
                    </b-field>
                  </div>
                  <b-field label="Comments" expanded class="mt-2">
                    <b-input
                      maxlength="400"
                      type="textarea"
                      v-model="form.comments.data"
                    ></b-input>
                  </b-field>
                </div>
                <b-message
                  auto-close
                  :duration="10000"
                  :active.sync="form.responce"
                  :type="form.status"
                >
                  {{ form.message }}
                </b-message>
              </div>
              <footer class="card-footer">
                <span class="card-footer-item buttons card-foot">
                  <b-button
                    type="is-outlined is-danger is-justified-center"
                    @click="cancelOrder()"
                    ><i class="fas fa-trash"></i> Cancel Order</b-button
                  >
                  <b-button
                    type="is-success is-justified-center"
                    @click="placeOrder()"
                    ><i class="fas fa-check"></i> Confirm Order</b-button
                  >
                </span>
              </footer>
            </div>
          </div>
        </div>
        <!-- end main column -->
      </div>
    </section>
  </div>
</template>

<script>
import UserMenu from "@/components/UserMenu.vue";
import RequestList from "@/components/RequestList.vue";
import { mapState } from "vuex";

export default {
  name: "requests",
  components: {
    UserMenu,
    RequestList
  },
  metaInfo() {
    return {
      title: this.$mySite.title,
      titleTemplate: "Requests | %s",
      htmlAttrs: {
        lang: "en"
      },
      meta: [
        {
          name: "description",
          content: "Requested items from storage"
        },
        {
          property: "og:title",
          content: this.$mySite.title,
          vmid: "og:title"
        },
        {
          property: "og:description",
          content: "Requested items from storage",
          vmid: "og:description"
        },
        {
          property: "og:image",
          content: this.$mySite.image,
          vmid: "og:image"
        },
        {
          property: "og:url",
          content: this.$mySite.url,
          vmid: "og:url"
        }
      ]
    };
  }, //end meta
  data() {
    return {
      RequestListIcon: "frown",
      RequestListKey: 0,
      delivery: true,
      listRequested: null,
      isLoading: false,
      form: {
        project: {
          data: null,
          message: null,
          status: null
        },
        name: {
          data: null,
          message: null,
          status: null
        },
        company: {
          data: null,
          message: null,
          status: null
        },
        email: {
          data: null,
          message: null,
          status: null
        },
        phone: {
          data: null,
          message: null,
          status: null
        },
        address: {
          data: null,
          message: null,
          status: null
        },
        optional: {
          data: null,
          message: null,
          status: null
        },
        city: {
          data: null,
          message: null,
          status: null
        },
        postal: {
          data: null,
          message: null,
          status: null
        },
        state: {
          data: null,
          message: null,
          status: null
        },
        country: {
          data: null,
          message: null,
          status: null
        },
        comments: {
          data: null,
          message: null,
          status: null
        },
        responce: false,
        message: null,
        status: null
      }
    };
  },
  computed: {
    // isLoged: function() {
    //   return this.$store.state.isLoged;
    // }
    ...mapState(["requests", "rNum"])
  },
  methods: {
    cancelOrder() {
      if (this.rNum > 0) {
        console.log("Canceling Order...");
        this.$store.commit("REM_ALL");
        this.RequestListIcon = "frown";
        this.RequestListKey++;
      } else {
        this.form.responce = true;
        this.form.message =
          "Please add Items to the Request List to be able to proceed with your Order.";
        this.form.status = "is-danger";
        return;
      }
    },
    sendNotification(oid) {
      console.log("Sending Notification...");
      //begin post --------------------------------------------
      this.axios
        .post("data/notify.php", {
          //.post("testmail.php", {
          token: this.$store.getters.IS_JWT,
          oid: oid,
          project: this.form.project.data,
          client_email: this.accountlInfo.email,
          client_name: this.accountlInfo.name,
          client_company: this.accountlInfo.company,
          name: this.form.name.data,
          company: this.form.company.data,
          phone: this.form.phone.data,
          address: this.form.address.data,
          optional: this.form.optional.data,
          city: this.form.city.data,
          state: this.form.state.data,
          country: this.form.country.data,
          postal: this.form.postal.data,
          comments: this.form.comments.data,
          itemsIDS: this.listRequested,
          action: "send"
        })
        .then(response => {
          console.log(response.data);
          this.toast("Warehouse was notified!", "is-success");
        })
        .catch(error => {
          console.log("sendNotification: "+error);
        });
      //end post-------------------------------------------------
    },
    placeOrder() {
      console.log("Placing Order...");
      //.log("With data...");
      this.listRequested = this.requests.join(",");
      console.log("With data...:" + this.listRequested);
      if (this.rNum > 0) {
        console.log("has requests");
      } else {
        this.form.responce = true;
        this.form.message =
          "Please add Items to the Request List to be able to proceed with your Order.";
        this.form.status = "is-danger";
        return;
      }
      if (
        this.formCheck("project") &
        this.formCheck("name") &
        this.formCheck("company") &
        this.formCheck("address") &
        this.formCheck("phone") &
        this.formCheck("postal") &
        this.formCheck("city") &
        this.formCheck("state") &
        this.formCheck("country")
      ) {
        console.log("form checked!");
        console.log("sending to:" + this.form.email.data);
        this.isLoading = true;
        //begin post --------------------------------------------
        this.axios
          .post("data/manage_orders.php", {
            token: this.$store.getters.IS_JWT,
            project: this.form.project.data,
            email: this.form.email.data,
            name: this.form.name.data,
            company: this.form.company.data,
            phone: this.form.phone.data,
            address: this.form.address.data,
            optional: this.form.optional.data,
            city: this.form.city.data,
            state: this.form.state.data,
            country: this.form.country.data,
            postal: this.form.postal.data,
            comments: this.form.comments.data,
            itemsIDS: this.listRequested,
            action: "addOrder"
          })
          .then(response => {
            this.isLoading = false;
            this.form.responce = true;
            this.form.message = response.data.message;
            console.log(response.data);
            if (response.data.success) {
              this.form.status = "is-success";
              //console.log(response.data);
              this.$store.commit("REM_ALL");
              this.RequestListIcon = "smile";
              this.RequestListKey++;
              this.sendNotification(response.data.orderID);
            } else {
              this.form.status = "is-danger";
              //console.log(response.data);
            }
          })
          .catch(error => {
            this.isLoading = false;
            console.log(error);
            this.form.responce = true;
            this.form.message =
              "Sorry, something went wrong, please try again.";
          });
        //end post-------------------------------------------------
      } else {
        console.log("error!");
        this.isLoading = false;
        this.form.responce = true;
        this.form.status = "is-danger";
        this.form.message = "Please fill out all required fields.";
      }
    },
    formCheck(e) {
      if (this.form[e].data === "" || this.form[e].data === null) {
        this.form[e].message = "This field is required";
        this.form[e].status = "is-danger";
        return false;
      } else {
        this.form[e].message = null;
        this.form[e].status = "is-success";
        return true;
      }
    },
    emailCheck(e) {
      let re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      if (this.form[e].data === "" || !re.test(this.form[e].data)) {
        this.form[e].message = "Please enter a valid Email";
        this.form[e].status = "is-danger";
        return false;
      } else {
        this.form[e].message = null;
        this.form[e].status = "is-success";
        return true;
      }
    },
    requestsCheck(e) {
      if (e === "") {
        return false;
      } else {
        this.form.responce = false;
        return true;
      }
    },
    toast(message, type) {
      this.$buefy.toast.open({
        position: "is-bottom",
        message: message,
        type: type
      });
      // this.$buefy.notification.open({
      //   duration: 5000,
      //   message: message,
      //   position: "is-bottom",
      //   type: type,
      //   hasIcon: true
      // });
    },
    getUserData() {
      //begin post --------------------------------------------
      this.axios
        .post("user/manage_user.php", {
          token: this.$store.getters.IS_JWT,
          action: "getUser"
        })
        .then(response => {
          console.log(response.data);
          if (response.data.success) {
            this.accountlInfo = response.data.user;
            (this.form.name.data = response.data.user.name),
              (this.form.email.data = response.data.user.email),
              (this.form.company.data = response.data.user.company),
              (this.form.address.data = response.data.user.address),
              (this.form.optional.data = response.data.user.optional),
              (this.form.phone.data = response.data.user.phone),
              (this.form.city.data = response.data.user.city),
              (this.form.state.data = response.data.user.state),
              (this.form.country.data = response.data.user.country),
              (this.form.postal.data = response.data.user.postal);
          } else {
            console.log("some error!");
          }
        })
        .catch(error => {
          console.log("getUserData "+error);
        });
      //end post-------------------------------------------------
    },
    restoreUserData() {
      console.log("restoring adress");
      (this.form.name.data = this.accountlInfo.name),
        (this.form.email.data = this.accountlInfo.email),
        (this.form.company.data = this.accountlInfo.company),
        (this.form.address.data = this.accountlInfo.address),
        (this.form.optional.data = this.accountlInfo.optional),
        (this.form.phone.data = this.accountlInfo.phone),
        (this.form.postal.data = this.accountlInfo.postal),
        (this.form.city.data = this.accountlInfo.city),
        (this.form.state.data = this.accountlInfo.state),
        (this.form.country.data = this.accountlInfo.country);
    }
  },
  mounted() {
    this.getUserData();
  },
  watch: {
    delivery: function(val) {
      if (val) {
        this.restoreUserData();
      }
    }
  }
};
</script>

<style scoped>
.card-foot {
  justify-content: flex-end; /* space-between flex-end; */
}
.hero-body.title {
  color: black;
}
</style>
